<template>
  <div class="modal-backdrop" v-if="this.$store.state.modal.createUser">
    <div class="modal-mask" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Создание пользователя</h5>
            <button type="button" class="btn-close" @click="close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label class="col-form-label">Телефон</label>
              <input type="text" class="form-control" placeholder="89999999999" v-model="user.phone">
              <div class="form-text">Формат телефон: 89999999999</div>
            </div>
            <div class="mb-3">
              <label class="col-form-label">ФИО</label>
              <input type="text" class="form-control" placeholder="Введите ФИО" v-model="user.full_name">
            </div>
            <div class="mb-3">
              <label class="col-form-label">Email</label>
              <input type="text" class="form-control" placeholder="Введите Email" v-model="user.email">
            </div>
            <div class="mb-3">
              <label class="col-form-label">Пароль</label>
              <input type="text" class="form-control" placeholder="Введите пароль" v-model="user.password">
              <div class="form-text">Указывать пароль нужно только для администратора</div>
            </div>
            <div class="mb-3">
              <label class="col-form-label">Выберите права</label>
              <select class="form-select" v-model="user.permission">
                <option value="0">Пользователь</option>
                <option value="100">Администратор</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Закрыть</button>
            <button type="button" class="btn btn-primary" @click="createUser">Создать</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: 'create-user',
  data: function () {
    return {
      user: {
        phone: '',
        full_name: '',
        email: '',
        password: '',
        permission: 0
      }
    }
  },
  methods: {
    createUser: function () {
      let $this = this;
      this.$store.dispatch('createUser', this.user).then((val) => {
        if(val.data.result === 'ok'){
          $this.user.phone = '';
          $this.user.full_name = '';
          $this.user.email = '';
          $this.user.password = '';
          $this.user.permission = 0;
          this.$root.$emit('updateClientList');
          Vue.$toast.success(val.data.message);
          this.close();
        }else{
          Vue.$toast.error(val.data.message);
        }
      });
    },
    close: function () {
      this.$store.state.modal.createUser = false;
    },
  },
};
</script>
